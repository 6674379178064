@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");

.jumbotron{

	background-color: #444;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	color: #fff;

	text-shadow: 1px 1px #000;

}

.jumbotron *{
	color: #fff !important;
}


.jumbotron-below-navbar,
.page-header-below-navbar{
	margin-top: -23px !important;
}

.nav-stacked{
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.control-label.checkbox-label{
	text-align: left !important;
}

.footer{

	margin-top: 2em;
	padding: 2em 0;

	background: #dfffcc; 

}

table p{
	margin: 0;
}

